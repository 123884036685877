import React, { useCallback, useEffect, useState, useRef, useTransition } from 'react'
import Loader from '../../components/Loader/Loader'
import 'ag-grid-enterprise'
import '../../../node_modules/react-grid-layout/css/styles.css'
import '../../../node_modules/react-resizable/css/styles.css'
import './TradeBlotter.css'
import { AgGridReact } from 'ag-grid-react'
import {
  PaperStyle,
  ButtonAmendTrade,
  ButtonGroupTrade,
  TradeBlotterTool,
  ButtonAllocateTrade,
  ButtonGiveUp,
  IconButtonStyle,
  ButtonDeleted,
  TypographyStyle,
  TooltipStyle
} from './TradeBlotter.style'
import { webSocketWorkerEvent } from '../../services'
import { Box, Grid } from '@mui/material'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { ModuleRegistry } from 'ag-grid-community'
import { useDispatch, useSelector } from 'react-redux'
import { handelNotifications } from '../../redux/slices/Notification/notificationSlice'
import { DATE_FORMAT, SIGN } from '../../utils/constants'
import FilterTable from './components/FilterTable/FilterTable'
import {
  changeUtcTimeToLocal,
  checkObjectAndArrayWithStringify,
  cleanEmptyField,
  paginationHandler
} from '../../helper/index'
import { ReactComponent as WarningIcon } from '../../assets/warning.svg'
import { ReactComponent as UnionIcon } from '../../assets/union.svg'
import { ReactComponent as BloombergConnected } from '../../assets/bloomberg_icon_orange.svg'
import { ReactComponent as BloombergNotConnected } from '../../assets/bloomberg_icon_green.svg'
import dayjs from 'dayjs'
import dayjsPluginUTC from 'dayjs-plugin-utc'
import SearchTextField from '../../components/SearchTextField/SearchTextField'
import Pagination from '../../components/Pagination/Pagination'
import useTradeBlotterAgGridSetting from './hooks/AgGrid.setting.js'
import useToolBarSetting from './hooks/toolBar.setting.js'
import useBloombergSetting from './hooks/bloomberg.setting.js'
import { Responsive, WidthProvider } from 'react-grid-layout'
import AppsIcon from '@mui/icons-material/Apps'
import { ButtonPrices } from './TradeBlotter.style'
import { ReactComponent as PriceMenuIcon } from '../../assets/prices_icon.svg'
import useAgGridPriceSettings from './hooks/AgGridPrice.settings.js'
import { defaultLayout } from './data/layout.js'
import MenuButton from './components/MenuButton/MenuButton.js'
const ResponsiveGridLayout = WidthProvider(Responsive)

dayjs.extend(dayjsPluginUTC, { parseToLocal: true })

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  MasterDetailModule,
  MenuModule,
  ClipboardModule,
  ExcelExportModule
])

const TradeBlotter = () => {
  const getLayoutFromLS = () => {
    if (localStorage.getItem('layouts') !== null) {
      const layoutStorage = JSON.parse(global.localStorage.getItem('layouts'))
      return layoutStorage
    } else {
      return defaultLayout
    }
  }

  const [data, setData] = useState([])
  const [bloombergConnection, setBloombergConnection] = useState(false)
  const [, setMenuPriceLoading] = useState(true)
  const initialFilterState = useCallback(() => {
    return {
      id: '',
      trade_date_start: '',
      trade_date_end: '',
      value_date_start: '',
      value_date_end: '',
      search: '',
      status_warning: false,
      status_union: false,
      type: [],
      symbol: [],
      prime_broker: [],
      counterparty: [],
      deal_offset: 0,
      trade_offset: 0
    }
  }, [])
  const [dealTradesGroup, setDealTradesGroup] = useState({})
  const [dealTradesAllocateGroup, setDealTradesAllocatedGroup] = useState({})
  const [tradesGroup, setTradesGroup] = useState({})
  const [dealsGroup, setDealsGroup] = useState({})
  const [pendingData, setPendingData] = useState({ data: [], status: null })
  const [dealsGiveUpGroup, setDealsGiveUpGroup] = useState({})
  const gridRef = useRef(null)
  const gridScrollHight = useRef({ top: 0 })
  const [, startTransition] = useTransition()
  const [page, setPage] = useState(1)
  const [disabled, setDisabled] = useState({ next: false, prev: true })
  const [pagination, setPagination] = useState([{}])
  const [filters, setFilters] = useState(initialFilterState())
  const [loading, setLoading] = useState(true)
  const [tradeBlotterResponse, setTradeBlotterResponse] = useState({ status: '', data: [] })
  const [selected, setSelected] = useState(0)
  const [openPrice, setOpenPrice] = useState(false)
  const token = useSelector(state => state.auth.token)
  const dispatch = useDispatch()
  const storage = getLayoutFromLS()
  const [layouts, setLayouts] = useState(storage)
  const bloombergPriceSnapshot = useSelector(state => state.bloomberg.data)

  const { handleClick, defaultColDef2, columnsDefs2, getRowId2 } = useAgGridPriceSettings({
    setOpenPrice,
    getLayoutFromLS,
    openPrice,
    setLayouts,
    defaultLayout
  })
  const dispatchNotification = (message, type) => {
    dispatch(
      handelNotifications({
        id: Date.now(),
        type,
        message
      })
    )
  }

  const {
    onRowClicked,
    columnsDefs,
    defaultColDef,
    isRowMaster,
    getRowId,
    onRowDoubleClicked,
    detailCellRenderer,
    getRowStyle,
    getContextMenuItems,
    onBodyScroll
  } = useTradeBlotterAgGridSetting({
    selected,
    setSelected,
    gridRef,
    dealTradesGroup,
    setDealTradesGroup,
    cleanCheckBoxState,
    dealTradesAllocateGroup,
    setDealTradesAllocatedGroup,
    dealsGiveUpGroup,
    setDealsGiveUpGroup,
    tradesGroup,
    setTradesGroup,
    dealsGroup,
    setDealsGroup,
    dispatch,
    token,
    pendingData,
    tradeBlotterResponse,
    gridScrollHight,
    setTradeBlotterResponse,
    setPendingData
  })

  const {
    handelAllocateTrade,
    handelTradeDealDelete,
    handelGroupButtonRender,
    handelDealGiveUp,
    handelUnGroupDeals,
    handelAmendTrade,
    handelGroupTrade,
    disabledGroupTrade,
    disabledAllocateTrade,
    disabledUnGroupDeal,
    disabledAmendTrade,
    disabledDealGiveUp,
    disabledDeleteTradeDeal
  } = useToolBarSetting({
    dealsGroup,
    tradesGroup,
    dealTradesAllocateGroup,
    dealsGiveUpGroup,
    dealTradesGroup,
    dispatchNotification
  })

  useBloombergSetting({
    tradeBlotterResponse,
    data,
    setData,
    setMenuPriceLoading,
    setBloombergConnection,
    bloombergPriceSnapshot
  })

  const handelGlobalFilterIcon = name => {
    const copyFilters = JSON.parse(JSON.stringify(filters))
    if (name === 'status_warning') {
      copyFilters.status_warning = !copyFilters.status_warning
    }
    if (name === 'status_union') {
      copyFilters.status_union = !copyFilters.status_union
    }

    setFilters(copyFilters)
    cleanCheckBoxState()
    cleanEmptyField(copyFilters)
    cleanPaginationFilter(copyFilters)
    webSocketWorkerEvent.sendEvent({ type: 'get_trade_blotter', data: copyFilters })
  }

  const handelGlobalSearchFilter = event => {
    startTransition(() => {
      const { value } = event.target
      const copyFilters = JSON.parse(JSON.stringify(filters))
      copyFilters.search = value
      setFilters(copyFilters)
      cleanCheckBoxState()
      cleanEmptyField(copyFilters)
      cleanPaginationFilter(copyFilters)
      webSocketWorkerEvent.sendEvent({ type: 'get_trade_blotter', data: copyFilters })
    })
  }

  function cleanCheckBoxState() {
    if (Object.keys(tradesGroup).length !== 0) {
      setTradesGroup({})
    }
    if (Object.keys(dealTradesGroup).length !== 0) {
      setDealTradesGroup({})
    }
    if (Object.keys(dealsGroup).length !== 0) {
      setDealsGroup({})
    }
    if (Object.keys(dealTradesAllocateGroup).length !== 0) {
      setDealTradesAllocatedGroup({})
    }
    if (Object.keys(dealsGiveUpGroup).length !== 0) {
      setDealsGiveUpGroup({})
    }
    if (page !== 1) {
      setPage(1)
    }
    if (selected !== 0) {
      setSelected(0)
    }
  }

  useEffect(() => {
    webSocketWorkerEvent.sendEvent({ type: 'get_trade_blotter', data: {} })
  }, [])

  useEffect(() => {
    const handelMessage = message => {
      if (
        (message.data.type === 'get_trade_blotter' ||
          message.data.type === 'get_trade_blotter_lucera') &&
        message.data.status === 'ok' &&
        message.data.data &&
        Array.isArray(message.data.data.tradeBlotterData)
      ) {
        let { status, data } = message.data
        const { tradeBlotterData, filters } = data
        if (tradeBlotterData.length === 0) {
          setTradeBlotterResponse({
            status,
            data: tradeBlotterData
          })
          return
        }
        setPagination(requests => {
          if (!checkObjectAndArrayWithStringify(requests, filters)) {
            requests[page] = filters
          }
          return requests
        })
        for (const element of tradeBlotterData) {
          element.trade_date = changeUtcTimeToLocal(
            element.trade_date,
            DATE_FORMAT.BLOTTER_DATE_TIME
          )
          if (Array.isArray(element.trades)) {
            for (const trade of element.trades) {
              trade.trade_date = changeUtcTimeToLocal(
                trade.trade_date,
                DATE_FORMAT.BLOTTER_DATE_TIME
              )
            }
          }
        }
        paginationHandler(tradeBlotterData, page, { setDisabled, disabled })
        if (message.data.type === 'get_trade_blotter_lucera') {
          if (gridScrollHight.current.top > 200) {
            setPendingData({
              status,
              data: tradeBlotterData
            })
          } else {
            setTradeBlotterResponse({
              status,
              data: tradeBlotterData
            })
            if (pendingData.status !== null) {
              setPendingData({
                status: null,
                data: []
              })
            }
          }
        } else {
          if (pendingData.status !== null) {
            setPendingData({ data: [], status: null })
          }
          setTradeBlotterResponse({
            status,
            data: tradeBlotterData
          })
        }
        setLoading(false)
      }

      const SUCCESS = 'success'
      if (
        message.data.type === 'update_trade' &&
        message.data.status === 'ok' &&
        message.data.data.trade_id
      ) {
        const id = Number(message.data.data.trade_id)
        dispatchNotification(`trade: ${id} is being update`, SUCCESS)
        if (id in tradesGroup || id in dealTradesGroup) {
          setSelected(selected - 1)
        }

        setTradesGroup(lastTradeGroups => {
          delete lastTradeGroups[id]
          return lastTradeGroups
        })
        setDealTradesGroup(lastTradeGroups => {
          delete lastTradeGroups[id]
          return lastTradeGroups
        })
      }

      if (message.data.type === 'delete_trades_or_deals' && message.data.status === 'ok') {
        if (Array.isArray(message.data.data.trades_id) && message.data.data.trades_id.length > 0) {
          const { trades_id } = message.data.data
          dispatchNotification(`trades: ${trades_id.join(',')} is being delete`, SUCCESS)
          setDealTradesGroup(lastTradeGroups => {
            let counter = 0
            for (const tradeId of trades_id) {
              const id = Number(tradeId)
              if (id in dealTradesGroup) {
                counter++
                delete lastTradeGroups[id]
              }
            }
            setSelected(selected - counter)
            return lastTradeGroups
          })
        }

        if (Array.isArray(message.data.data.deals_id) && message.data.data.deals_id.length > 0) {
          const { deals_id } = message.data.data
          dispatchNotification(`deals: ${deals_id.join(',')} is being delete`, SUCCESS)

          setDealsGroup(lastDealGroup => {
            let counter = 0
            for (const dealId of deals_id) {
              const id = Number(dealId)
              if (id in lastDealGroup) {
                delete lastDealGroup[id]
                counter++
              }
            }
            setSelected(selected - counter)
            return lastDealGroup
          })
        }
      }
      if (
        message.data.type === 'group_trades' &&
        message.data.status === 'ok' &&
        Array.isArray(message.data?.data?.trades_id)
      ) {
        const { trades_id } = message.data.data
        const tradesId = trades_id.map(tradeId => Number(tradeId))
        dispatchNotification(`trades: ${tradesId.join(',')} are being group to a deal`, SUCCESS)
        setTradesGroup(lastTradeGroups => {
          let counter = 0
          for (const tradeId of trades_id) {
            const id = Number(tradeId)
            if (id in lastTradeGroups) {
              counter++
              delete lastTradeGroups[id]
            }
          }
          if (counter > 0) {
            setSelected(selected - counter)
          }
          return lastTradeGroups
        })
      }
      if (
        message.data.type === 'un_group_deals' &&
        message.data.status === 'ok' &&
        Array.isArray(message.data?.data?.deals_id)
      ) {
        const { deals_id } = message.data.data
        const dealsId = deals_id.map(dealId => Number(dealId))
        dispatchNotification(`deals: ${dealsId.join(',')} is/are being ungrouped`, SUCCESS)

        setDealsGroup(lastDealGroups => {
          let counter = 0
          for (const id of dealsId) {
            if (id in lastDealGroups) {
              counter++
              delete lastDealGroups[id]
            }
          }
          if (counter > 0) {
            setSelected(selected - counter)
          }
          return lastDealGroups
        })

        setDealsGiveUpGroup(lastDealGiveUpGroup => {
          for (const id of dealsId) {
            delete lastDealGiveUpGroup[id]
          }
          return lastDealGiveUpGroup
        })
      }
      if (
        message.data.type === 'allocate_trade' &&
        message.data.status === 'ok' &&
        message.data.data.trade_id
      ) {
        const id = Number(message.data.data.trade_id)
        dispatchNotification(`trade: ${id} is being allocated`, SUCCESS)
        setDealTradesAllocatedGroup(lastDealTradesAllocate => {
          delete lastDealTradesAllocate[id]
          return lastDealTradesAllocate
        })
      }
      if (
        message.data.type === 'give_up_deals' &&
        message.data.status === 'ok' &&
        Array.isArray(message.data?.data?.deals_id)
      ) {
        const { deals_id } = message.data.data
        const dealsId = deals_id.map(dealId => Number(dealId))
        dispatchNotification(`deals: ${dealsId.join(',')} is/are being Live`, SUCCESS)
        setDealsGiveUpGroup(lastDealGiveUpGroup => {
          for (const id of dealsId) {
            delete lastDealGiveUpGroup[id]
          }
          return lastDealGiveUpGroup
        })
      }
    }

    webSocketWorkerEvent.addEventListener('message', handelMessage)
    return () => webSocketWorkerEvent.removeEventListener('message', handelMessage)
  }, [
    webSocketWorkerEvent,
    gridScrollHight,
    tradeBlotterResponse,
    filters,
    selected,
    disabled,
    page,
    tradesGroup,
    dealsGroup
  ])

  const cleanPaginationFilter = useCallback(() => {
    setPage(1)
    setPendingData({ data: [], status: null })
    setDisabled({ prev: true, next: false })
  }, [])

  const handelPagination = sign => {
    const paginationCall = sign === SIGN.NEXT ? pagination[page] : pagination[page - 2]
    const newPage = sign === SIGN.NEXT ? page + 1 : page - 1
    if (newPage !== 1) {
      setDisabled({ prev: false, next: false })
    }
    setPage(newPage)

    let data = {
      ...filters,
      ...paginationCall
    }
    cleanEmptyField(data)
    webSocketWorkerEvent.sendEvent({
      type: 'get_trade_blotter',
      data: data
    })
    setLoading(true)
  }

  const onLayoutChange = (layout, layouts) => {
    setLayouts(layouts)
    localStorage.setItem('layouts', JSON.stringify(layouts))
  }

  return (
    <PaperStyle className={'ag-theme-quartz-dark'}>
      <TradeBlotterTool>
        <Box>
          {handelGroupButtonRender() ? (
            <ButtonGroupTrade
              variant="contained"
              onClick={handelGroupTrade}
              disabled={disabledGroupTrade()}
            >
              Group
            </ButtonGroupTrade>
          ) : (
            <ButtonGroupTrade
              variant="contained"
              onClick={handelUnGroupDeals}
              disabled={disabledUnGroupDeal()}
            >
              unGroup
            </ButtonGroupTrade>
          )}

          <ButtonAmendTrade
            variant="contained"
            onClick={handelAmendTrade}
            disabled={disabledAmendTrade()}
          >
            Amend
          </ButtonAmendTrade>

          <ButtonAllocateTrade
            variant="contained"
            onClick={handelAllocateTrade}
            disabled={disabledAllocateTrade()}
          >
            Allocate
          </ButtonAllocateTrade>
          <ButtonGiveUp
            variant="contained"
            onClick={handelDealGiveUp}
            disabled={disabledDealGiveUp()}
          >
            Give-Up
          </ButtonGiveUp>
          <ButtonDeleted
            variant="contained"
            onClick={handelTradeDealDelete}
            disabled={disabledDeleteTradeDeal()}
          >
            Delete
          </ButtonDeleted>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <MenuButton />
          <IconButtonStyle
            press={String(filters.status_warning)}
            onClick={() => handelGlobalFilterIcon('status_warning')}
          >
            <WarningIcon />
          </IconButtonStyle>
          <IconButtonStyle
            press={String(filters.status_union)}
            onClick={() => handelGlobalFilterIcon('status_union')}
          >
            <UnionIcon />
          </IconButtonStyle>
          <FilterTable
            page={page}
            WSMessageType={'get_trade_blotter'}
            filters={filters}
            setFilters={setFilters}
            cleanCheckBoxState={cleanCheckBoxState}
            setPage={setPage}
          />

          <SearchTextField
            name="search"
            handleChange={handelGlobalSearchFilter}
            state={filters.search}
          />

          <ButtonPrices onClick={handleClick} startIcon={<PriceMenuIcon />}>
            Prices
          </ButtonPrices>
        </Box>
      </TradeBlotterTool>
      {loading && <Loader />}
      {!loading && tradeBlotterResponse.status === 'ok' && (
        <>
          <ResponsiveGridLayout
            className="layout"
            layouts={layouts}
            draggableHandle=".MyDragHandleClassName"
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            onLayoutChange={onLayoutChange}
            autoSize={true}
          >
            <div key="1" style={{ height:"70dvh", maxHeight: '70dvh' }}>
              <AppsIcon
                className="MyDragHandleClassName"
                style={{ width: '20px', marginRight: '5px' }}
              />
              <AgGridReact
                ref={gridRef}
                columnDefs={columnsDefs}
                masterDetail={true}
                detailCellRenderer={detailCellRenderer}
                defaultColDef={defaultColDef}
                suppressRowClickSelection={true}
                getRowStyle={getRowStyle}
                rowData={tradeBlotterResponse.data}
                rowMultiSelectWithClick={true}
                suppressScrollOnNewData={true}
                detailRowAutoHeight={true}
                getRowId={getRowId}
                onBodyScroll={onBodyScroll}
                onRowClicked={onRowClicked}
                onRowDoubleClicked={onRowDoubleClicked}
                getContextMenuItems={getContextMenuItems}
                groupUseEntireRow={true}
                isRowMaster={isRowMaster}
                rowSelection={'multiple'}
              />
              <Pagination
                handelNextPagination={() => handelPagination(SIGN.NEXT)}
                handelPrevPagination={() => handelPagination(SIGN.PREV)}
                page={page}
                disabledPrev={disabled.prev}
                disabledNext={disabled.next}
              />
            </div>
            {openPrice && (
              <div key="2" style={{ height:"70dvh", maxHeight: '70dvh' }}>
                <AppsIcon
                  className="MyDragHandleClassName"
                  style={{ width: '20px', marginRight: '5px' }}
                />
                <AgGridReact
                  columnDefs={columnsDefs2}
                  rowData={data}
                  getRowId={getRowId2}
                  defaultColDef={defaultColDef2}
                />
              </div>
            )}
          </ResponsiveGridLayout>
        </>
      )}
      <Grid style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '48px' }}>
        <Grid>
          <TooltipStyle
            title={bloombergConnection ? 'Bloomberg Connected' : 'Bloomberg Disconnected'}
            placement="top"
            color="#FF9747"
          >
            {bloombergConnection ? (
              <BloombergNotConnected style={{ margin: '-2px' }} />
            ) : (
              <BloombergConnected style={{ margin: '-2px' }} />
            )}
          </TooltipStyle>
        </Grid>
        <Grid>
          {!bloombergConnection ? <TypographyStyle>Using indicative rates</TypographyStyle> : null}
        </Grid>
      </Grid>
    </PaperStyle>
  )
}

export default TradeBlotter
