import { combineReducers } from '@reduxjs/toolkit'
import modalReducer from './slices/Modal/modalSlice'
import notificationReducer from './slices/Notification/notificationSlice'
import authReducer from './slices/Auth/AuthSlice'
import bloombergReducer from './slices/Bloomberg/bloombergSlice'

const appReducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
  bloomberg: bloombergReducer,
  notification: notificationReducer
})

export default appReducer
