import React from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import styled from '@emotion/styled'
import { Box, Button, Checkbox, Paper, Typography, Menu, IconButton } from '@mui/material'
import { ReactComponent as DownloadComponent } from '../../assets/download.svg'
import { ReactComponent as CopyComponent } from '../../assets/copy.svg'
import { ReactComponent as InfoDealStatusComponent } from '../../assets/info menu.svg'
import { STATUS_CURRENCY_PAIR } from '../../utils/constants'

export const PaperStyle = styled(Paper)(({ theme }) => {
  return {
    boxShadow: "none",
    height: "80dvh",
    width: 'calc(100dvw - 120px)',
    marginTop: '8px',
    margin: '8px',
    backgroundColor: theme.palette.tabs.backgroundColor.hover
  }
})

export const ButtonGroupTrade = styled(Button)(({ theme }) => {
  return {
    border: `1px solid ${theme.palette.button.save.primary}`,
    backgroundColor: theme.palette.tabs.backgroundColor.hover,
    color: theme.palette.button.text.primary,
    fontFamily: 'Nunito',
    fontSize: '14px',
    height: '32px',
    padding: '11px 24px',
    marginRight: '12px',
    textTransform: 'capitalize',
    '&:hover': {
      border: `1px solid ${theme.palette.button.save.hover}`,
      color: theme.palette.button.save.hover,
      backgroundColor: theme.palette.tabs.backgroundColor.hover
    },
    '&:disabled': {
      border: `1px solid ${theme.palette.button.disable}`,
      color: theme.palette.svg.clearIcon
    }
  }
})

export const ButtonAmendTrade = styled(Button)(({ theme }) => {
  return {
    backgroundColor: theme.palette.button.edit.primary,
    padding: '11px 24px',
    color: 'black',
    height: '32px',
    fontSize: '14px',
    fontFamily: 'Nunito',
    marginRight: '12px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.button.edit.hover
    },
    '&:disabled': {
      background: theme.palette.button.disable,
      color: theme.palette.svg.clearIcon
    }
  }
})

export const ButtonAllocateTrade = styled(Button)(({ theme }) => {
  return {
    backgroundColor: theme.palette.button.allocate.primary,
    padding: '11px 24px',
    color: theme.palette.button.text.primary,
    height: '32px',
    fontSize: '14px',
    fontFamily: 'Nunito',
    marginRight: '12px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.button.allocate.hover
    },
    '&:disabled': {
      background: theme.palette.button.disable,
      color: theme.palette.svg.clearIcon
    }
  }
})

export const ButtonGiveUp = styled(Button)(({ theme }) => {
  return {
    backgroundColor: theme.palette.tabs.backgroundColor.hover,
    border: `1px solid ${theme.palette.button.giveUp.text}`,
    padding: '11px 24px',
    color: theme.palette.button.giveUp.text,
    height: '32px',
    fontSize: '14px',
    fontFamily: 'Nunito',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.button.giveUp.hover
    },
    '&:disabled': {
      background: theme.palette.button.disable,
      color: theme.palette.svg.clearIcon,
      border: `0px`
    }
  }
})

export const ButtonPrices = styled(Button)(({ theme }) => {
  return {
    backgroundColor: '#39394e',
    padding: '11px 16px 11px 12px',
    color: theme.palette.button.text.primary,
    borderColor: theme.palette.button.text.primary,
    height: '32px',
    fontSize: '14px',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Nunito',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.text.primary
    },
    '&:disabled': {
      background: theme.palette.button.disable,
      color: theme.palette.svg.clearIcon
    }
  }
})

export const ButtonDeleted = styled(Button)(({ theme }) => {
  return {
    backgroundColor: theme.palette.button.delete.primary,
    padding: '11px 24px',
    color: 'black',
    borderColor: theme.palette.button.delete.primary,
    height: '32px',
    fontSize: '14px',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '12px',
    fontFamily: 'Nunito',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.button.delete.hover
    },
    '&:disabled': {
      background: theme.palette.button.disable,
      color: theme.palette.svg.clearIcon
    }
  }
})

export const CheckboxTradeBlotter = styled(Checkbox)(({ theme }) => {
  return {
    position: 'absolute',
    top: 0,
    left: 0,
    '& .MuiSvgIcon-root': {
      color: theme.palette.separators.primary,
      backgroundColor: theme.palette.separators.primary,
      border: `${theme.palette.button.save.primary}`,
      borderRadius: '2px'
    },
    '&.Mui-checked .MuiSvgIcon-root': {
      color: `${theme.palette.button.save.primary} `,
      borderColor: `${theme.palette.button.save.primary}`,
      borderRadius: '2px'
    }
  }
})

export const TableCellStyle = styled(Box)(() => {
  return {
    border: 'none',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 ,15px',
    display: 'flex',
    minWidth: '200px'
  }
})

export const TooltipStyle = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, color }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 15,
    fontFamily: 'Nunito',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    color
  }
}))

export const TypographyStyle = styled(Typography)(() => {
  return {
    color: "var(--Secondary-text, #707079)",
    textAlign: "center",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Nunito",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal"
  }
})

export const TradeBlotterTool = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px'
}))

export const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme, style = {}, backgroundColor = '#171719', backgroundColorHover }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    backgroundColor: backgroundColor,
    marginTop: theme.spacing(1),
    minWidth: 180,
    ...style,
    color: theme.palette.text.primary,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:hover': {
        backgroundColor: backgroundColorHover || theme.palette.tabs.backgroundColor.hover
      }
    },
    '& .MuiList-root': {
      ...style,
      backgroundColor: 'transparent'
    }
  }
}))

export const IconButtonStyle = styled(IconButton)(({ theme, press }) => {
  let border = 'none'
  if (press === 'true') {
    border = `1px solid ${theme.palette.text.primary} `
  }

  return {
    border,
    height: '32px',
    width: '32px',
    borderRadius: '50%',
    backgroundColor: theme.palette.separators.primary,
    '&:hover': {
      backgroundColor: '#4F4F52'
    }
  }
})

export const TextQuantity = styled(Typography)(({ theme, quantity }) => {
  let color = 'white'
  if (+quantity < 0) {
    color = theme.palette.price.down
  }
  return {
    color,
    fontFamily: 'Nunito',
    fontWeight: 500,
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '10px'
  }
})

export const DownloadComponentStyle = styled(DownloadComponent)({
  paddingTop: '15px'
})

export const CopyComponentStyle = styled(CopyComponent)({
  paddingTop: '15px'
})

export const InfoDealStatusComponentStyle = styled(InfoDealStatusComponent)({
  paddingTop: '15px'
})

export const TextPriceStyle = styled(Typography)(({ theme, status }) => {
  let color = `${theme.palette.price.same}`
  switch (status) {
    case STATUS_CURRENCY_PAIR.UP:
      color = `${theme.palette.price.up}`
      break
    case STATUS_CURRENCY_PAIR.DOWN:
      color = `${theme.palette.price.down}`
      break
    case STATUS_CURRENCY_PAIR.SAME:
      break
    default:
      break
  }
  return {
    color,
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  }
})

export const PriceContainer = styled(Box)(theme => ({
  display: 'flex',
  position: 'absolute',
  height: '100%',
  width: '100%',
  minWidth: '200px',
  maxWidth: '200px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px'
}))

export const StyledMenu2 = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme, style = {}, backgroundColor = '#171719' }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    backgroundColor: backgroundColor,
    marginTop: theme.spacing(1),
    minWidth: 180,
    ...style,
    color: theme.palette.text.primary,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:hover': {
        backgroundColor: theme.palette.tabs.backgroundColor.hover
      }
    },
    '& .MuiList-root': {
      ...style,
      backgroundColor: 'transparent'
    }
  }
}))
