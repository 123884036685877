/* eslint-disable */
import { useEffect } from 'react'
import currencyList from '../../../constants/currencyList'
import { webSocketWorkerPriceEvent } from '../../../services'
import { createBloombergCurrency } from '../../../helper'
import { routes } from '../../../utils/constants'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setBloombergSnapshot } from '../../../redux/slices/Bloomberg/bloombergSlice'

const useBloombergSetting = ({
  tradeBlotterResponse,
  data,
  setData,
  setMenuPriceLoading,
  setBloombergConnection,
  bloombergPriceSnapshot
}) => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)

  const setDataWithLogic = newData => {
    if (newData.type === undefined && newData.security !== 'connection_status') {
      setData(prevData => {
        const index = prevData?.findIndex(price => price.security === newData.security)
        if (index !== -1 && index !== undefined) {
          if (newData.LAST_PRICE !== '') {
            if (newData.LAST_PRICE > prevData[index].LAST_PRICE) {
              newData.status = 'UP'
            } else if (newData.LAST_PRICE < prevData[index].LAST_PRICE) {
              newData.status = 'DOWN'
            } else {
              newData.status = 'UP'
            }
            const updatedData = [...prevData]
            updatedData[index] = newData
            return updatedData
          } else {
            return prevData
          }
        } else {
          if (newData.LAST_PRICE !== '') {
            newData.status = 'UP'
            return [...prevData, newData]
          } else {
            return prevData
          }
        }
      })
    }
  }

  const getUniqueStrings = deals => {
    const results = []
    const addedStrings = new Set() // Initialize Set for tracking unique strings
    deals.forEach(deal => {
      if (deal.trades?.length > 0 && deal.staging_status === 'Live' && deal.status === null) {
        // initial blumberg currency list
        if (deal.counter_currency !== 'USD') {
          const stringToAdd = `${deal.counter_currency}USD Curncy`
          const resultObject = createBloombergCurrency(deal)
          if (!addedStrings.has(stringToAdd)) {
            addedStrings.add(stringToAdd)
            results.push(resultObject)
          }
        }
      }
    })

    return results
  }

  const getBloombergPriceByCurrency = currency => {
    if (Array.isArray(data)) {
      let findCurrency = data.find(cu => {
        let base = cu.base_currency
        let counter = cu.counter_currency
        if (base + counter === currency) {
          return cu
        }
      })
      return findCurrency
    }
  }

  tradeBlotterResponse.data?.map(deal => {
    if (deal.trades?.length > 0 && deal.staging_status === 'Live' && deal.status === null) {
      if (deal.counter_currency !== 'USD') {
        let counter = deal.counter_currency + 'USD'
        let result = getBloombergPriceByCurrency(counter)
        deal.pnl_usd = deal.pnl * (result?.LAST_PRICE ?? null)
      } else {
        deal.pnl_usd = deal.pnl * 1
      }
    }
  })

  useEffect(() => {
    if (Array.isArray(tradeBlotterResponse.data) && tradeBlotterResponse.status === 'ok') {
      const uniqueStrings = getUniqueStrings(tradeBlotterResponse.data)
      const existingSecurities = new Set(currencyList?.map(item => item.security))

      uniqueStrings.forEach(unique => {
        if (!existingSecurities.has(unique.security)) {
          currencyList.push(unique)
          existingSecurities.add(unique.security)
        }
      })
      webSocketWorkerPriceEvent.sendEvent(currencyList)
    }
  }, [tradeBlotterResponse])

  useEffect(() => {
    const handelMessage = async message => {
      if (message?.data && message.type !== 'RPC' && message.data.security !== undefined) {
        if (message.data.security === 'connection_status') {
          if (message.data.status === true) {
            setBloombergConnection(true)
          } else {
            setBloombergConnection(false)
          }
        }
        setDataWithLogic(message.data)
        setMenuPriceLoading(false)
      } else {
        if (message.data.security === 'connection_status') {
          if (message.data.status === true) {
            setBloombergConnection(true)
          } else {
            setBloombergConnection(false)
          }
        }
      }
      if (bloombergPriceSnapshot.length > 0) {
        setData(bloombergPriceSnapshot)
        setMenuPriceLoading(false)
      }
      if (message.data && message.data.security === 'bloomberg_snapshot') {
        const url = process.env.REACT_APP_SERVER_URL.concat(routes.currencyPair, '/price')
        const result = await axios.get(url, { headers: { Authorization: token } })
        setData(result.data)
        dispatch(setBloombergSnapshot(result.data))
        setMenuPriceLoading(false)
      }
    }

    webSocketWorkerPriceEvent.addEventListener('message', handelMessage)

    return () => webSocketWorkerPriceEvent.removeEventListener('message', handelMessage)
  }, [webSocketWorkerPriceEvent])
}

export default useBloombergSetting
