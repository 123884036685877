import { MenuItem } from '@mui/material'
import React, { useMemo } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ButtonAddNew } from '../../../../components/styled/global.style'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../../redux/slices/Modal/modalSlice'
import { MODAL_TITLE, MODAL_TYPE } from '../../../../utils/constants'
import { StyledMenu } from '../../TradeBlotter.style'

function MenuButton() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const MENU_ITEMS = useMemo(
    () => ({
      B2B_SPOT: 'B2B Spot',
      CLIENT_SPOT: 'Client Spot',
      MARKET_MAKER_SPOT: 'Market Maker Spot'
    }),
    []
  )
  const dispatch = useDispatch()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handelClick = event => {
    const key = event.target.innerText
    switch (key) {
      case MENU_ITEMS.B2B_SPOT:
        dispatch(setModal({ type: MODAL_TYPE.DEAL, title: MODAL_TITLE.NEW_DEAL, isOpen: true }))
        break
      case MENU_ITEMS.CLIENT_SPOT:
        dispatch(setModal({ type: MODAL_TYPE.TRADE, title: MODAL_TITLE.NEW_TRADE, isOpen: true }))
        break
      case MENU_ITEMS.MARKET_MAKER_SPOT:
        dispatch(setModal({ type: MODAL_TYPE.TRADE, title: MODAL_TITLE.NEW_TRADE, isOpen: true }))
        break

      default:
        break
    }

    setAnchorEl(null)
  }

  return (
    <>
      <ButtonAddNew
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        New Trade
      </ButtonAddNew>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose} backgroundColor={'#39393C'}>
        <MenuItem onClick={handelClick} disableRipple name={MENU_ITEMS.B2B_SPOT}>
          B2B Spot
        </MenuItem>
        <MenuItem onClick={handelClick} disableRipple name={MENU_ITEMS.CLIENT_SPOT}>
          Client Spot
        </MenuItem>
        <MenuItem onClick={handelClick} disableRipple name={MENU_ITEMS.MARKET_MAKER_SPOT}>
          Market Maker Spot
        </MenuItem>
      </StyledMenu>
    </>
  )
}

export default MenuButton
