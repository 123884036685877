import { styled } from '@mui/system'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Card, Checkbox, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CircleIcon from '@mui/icons-material/Circle'

export const EditIconStyle = styled(EditIcon)(({ theme }) => ({
  color: theme.palette.button.edit.primary,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '8px',
  cursor: 'pointer',
  '& :hover': {
    color: theme.palette.button.edit.hover
  }
}))

export const DeleteIconStyle = styled(DeleteIcon)(({ theme }) => ({
  color: `${theme.palette.button.delete.primary}`,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '8px',
  cursor: 'pointer',
  '& :hover': {
    color: theme.palette.button.delete.hover
  }
}))

export const CheckboxStyle = styled(Checkbox)(({ theme }) => {
  return {
    '& .MuiSvgIcon-root': {
      color: 'black', // Default fill color for unchecked state
      backgroundColor: 'black',
      borderColor: `${theme.palette.button.save.primary}`,
      borderRadius: '2px'
    },
    '&.Mui-checked .MuiSvgIcon-root': {
      color: `${theme.palette.button.save.primary} `, // Fill color for checked state
      borderColor: `${theme.palette.button.save.primary}`,
      borderRadius: '2px'
    }
  }
})

export const TypographyDisabled = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.separators.primary,
  color: `${theme.palette.text.primary} !important`,
  borderRadius: '5px',
  height: '30px',
  padding: '5px'
}))

export const CheckIconStyle = styled(CheckIcon)(({ theme, isactive }) => {
  let display = 'none'
  if (+isactive === 1) {
    display = 'block'
  }
  return {
    display,
    width: '100%',
    marginTop: '5px',
    color: theme.palette.button.save.primary
  }
})

export const CircleIconStyle = styled(CircleIcon)(({ theme, isactive }) => {
  let color = theme.palette.switch.unactive
  if (+isactive === 1) {
    color = theme.palette.switch.active
  }

  return {
    color,
    width: '12px',
    height: '12px'
  }
})

export const MyCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.card.primary,
  paddingTop: '40px',
  paddingLeft: '48px',
  paddingRight: '48px',
  paddingBottom: '40px',
  borderRadius: '12px',
  [theme.breakpoints.down('md')]: {
    height: '100dvh',
    width: '100dvw'
  }
}))

export const CardTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Nunito',
  textAlign: 'center',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
}))

export const CardText = styled(Typography)(({ theme }) => ({
  color: theme.palette.svg.clearIcon,
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Nunito',
  textAlign: 'center',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
}))

export const Row = styled(Box)(() => ({
  display: 'flex'
}))

export const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: '4px',
  color: 'black',
  padding: '11px 24px',
  fontFamily: 'Nunito',
  textTransform: 'capitalize',
  height: '32px',
  fontSize: '14px',
  fontWeight: 700,
  marginLeft: '32px',
  backgroundColor: theme.palette.button.cancel.primary,
  '&:hover': {
    backgroundColor: theme.palette.button.cancel.hover
  }
}))

export const SaveChangesButton = styled(Button)(({ theme }) => ({
  borderRadius: '4px',
  color: 'black',
  padding: '11px 24px',
  fontFamily: 'Nunito',
  fontSize: '14px',
  height: '32px',
  fontWeight: 700,
  backgroundColor: theme.palette.button.save.primary,
  marginRight: '32px',
  '&:hover': {
    backgroundColor: theme.palette.button.save.hover
  },
  '&:disabled': {
    background: theme.palette.button.disable,
    color: theme.palette.svg.clearIcon
  }
}))

export const ButtonAddNew = styled(Button)(({ theme }) => ({
  background: theme.palette.button.addNew.primary,
  padding: '11px 24px',
  fontFamily: 'Nunito',
  height: '32px',
  fontSize: '14px',
  marginRight: '16px',
  textTransform: 'capitalize',
  fontWeight: 700,
  color: `${theme.palette.tabs.backgroundColor.primary}`,
  '&:hover': {
    backgroundColor: theme.palette.button.addNew.hover
  },
  '&:disabled': {
    background: theme.palette.button.disable,
    color: theme.palette.svg.clearIcon
  }
}))

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '12px',
  marginBottom: '8px',
  width: 'max-content'
}))
