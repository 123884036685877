import { useGridMenuItem } from 'ag-grid-react/lib/main'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CopyDownloadMenuContainer, MenuItemStyle, TextStyle } from './MenuItem.style'
import { SpinnerDealReportLoader } from '../../../../components/ModalApp/children/DealReportStaus/DealReportStatus.style'
import { useDispatch } from 'react-redux'
import { handelNotifications } from '../../../../redux/slices/Notification/notificationSlice'

const MenuItem = ({ name, icon, onMenuClick, disabled, dealId = 0 }) => {
  useGridMenuItem({
    configureDefaults: () => false
  })
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const handelClick = async () => {
    try {
      console.log(name)
      if (["Download Deal Report", 'Download Trade PB'].includes(name)) {
        setLoader(true)
      }
      const flag = await onMenuClick()

      if (flag && ["Download Deal Report", 'Download Trade PB'].includes(name)) {
        dispatch(handelNotifications({
          id: Date.now(),
          type: "success",
          message: `download Deal:${dealId} Report Successfully`
        }))
      }
    } catch (error) {
      console.log()
    }finally{
      if (["Download Deal Report", "Download Trade PB"].includes(name)) {
        setLoader(false)
      }
    } 
  }

  return <CopyDownloadMenuContainer className="deal-container" >
    {
      loader === false ?
        <MenuItemStyle onClick={handelClick} disabled={disabled}>
          {icon}
          <TextStyle>{name}</TextStyle>

        </MenuItemStyle> :
        <MenuItemStyle style={{ width: "220px", height: "45px" }}>
          <SpinnerDealReportLoader style={{
            marginLeft: "auto", marginRight: "auto", width: "250px"
          }} />
        </MenuItemStyle>
    }
  </CopyDownloadMenuContainer >


}

export default MenuItem

MenuItem.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  onMenuClick: PropTypes.func,
  name: PropTypes.string,
  dealId: PropTypes.number
}
